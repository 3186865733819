/* eslint-disable no-restricted-imports */
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { forwardRef, useEffect, useState } from "react";
import Dropdown from "react-bootstrap/Dropdown";
import { connect } from "react-redux";
import * as campaignsRedux from "../../modules/campaigns/redux/campaignsRedux";
import { Link } from "react-router-dom";
// import { useParams } from "react-router-dom";
import { post } from "../../networking/RequestService";
import SimpleModal from "../../modules/modal/simpleModal";
import ButtonWithText from "app/popleads/components/ui-kit/Button";

const QuickActionsDropdownToggle = forwardRef((props, ref) => {
    return (
        <a
            ref={ref}
            href="#"
            onClick={(e) => {
                e.preventDefault();
                props.onClick(e);
            }}
            id="kt_subheader_quick_course_actions"
        >
            <ButtonWithText variant="neutralInverted">Quick Actions</ButtonWithText>
        </a>
    );
});

function CourseActionDropdown(props) {
    const [isRequestSuccess, setIsRequestSuccess] = useState(false);
    const [errorMessage, setErrorMessage] = useState(null);
    const [reqObj, setReqObj] = useState({});
    const [showActionButtons, setShowActionButtons] = useState(false);

    useEffect(() => {
        if (props.selectedCampaign) {
            setReqObj({
                fn: "changeTemplateStatus",
                id: props.selectedCampaign.id,
            });
        }
    }, [props.selectedCampaign]);

    const changeStatus = (status) => {
        props.setCourseDetailLoading(true);
        setReqObj({
            ...reqObj,
            status,
        });
        post({ ...reqObj, status })
            .then(() => {
                setIsRequestSuccess(true);
                props.setCourseStatus(status);
                props.setCourseDetailLoading(false);
            })
            .catch((err) => {
                setErrorMessage(err.response.data.text);
                props.setCourseDetailLoading(false);
            });
    };

    // const permissions = (props.user && props.user.permissions) ? props.user.permissions : null;
    // #:~:text=ADD%20TO%20CART-,DOWNLOAD%20BROCHURE,-Course%20Overview
    /**
     * https://www.leoron.com/ar/cipd-level-5-diploma-in-people-management#:~:text=%D8%A3%D8%B6%D9%81%20%D8%A5%D9%84%D9%89%20%D8%A7%D9%84%D8%B9%D8%B1%D8%A8%D8%A9-,%D9%84%D8%AA%D8%AD%D9%85%D9%8A%D9%84%20%D8%A7%D9%84%D9%83%D8%AA%D9%8A%D8%A8,-%D9%86%D8%A8%D8%B0%D8%A9%20%D8%B9%D9%86%20%D8%A7%D9%84%D8%AF%D9%88%D8%B1%D8%A9
     */

    return (
        <>
            {errorMessage && (
                <SimpleModal
                    title=""
                    body={errorMessage}
                    className="d-flex flex-column align-items-center"
                    bodySvgUrl="/media/svg/popleads/error_sign.svg"
                    bodyClass="font-weight-semibold font-size-20px"
                    primaryButton="Okay"
                    onClose={(resp) => {
                        setErrorMessage(null);
                    }}
                />
            )}
            {isRequestSuccess && (
                <SimpleModal
                    title=""
                    body="Status of Course <br> Successfully Changed"
                    bodySvgUrl="/media/svg/popleads/simple_modal_check_mark.svg"
                    bodySvgClass="svg-icon-success"
                    className="d-flex flex-column align-items-center"
                    bodyClass="font-weight-semibold font-size-24px"
                    successOutlinedButton="OK"
                    onClose={(resp) => {
                        setIsRequestSuccess(false);
                    }}
                />
            )}

            {/* DESKTOP DROPDOWN BUTTONS */}
            <div className="courseQuickActionButtons-desktop">
                <Dropdown
                    className="dropdown-inline"
                    drop="down"
                    alignRight
                    // style={{ minWidth: "165px" }}
                    show={showActionButtons}
                    onToggle={() => setShowActionButtons(!showActionButtons)}
                >
                    <Dropdown.Toggle as={QuickActionsDropdownToggle} id="dropdown-toggle-quick-actions-subheader" />
                    <Dropdown.Menu className="dropdown-menu p-0 m-0 mt-2 dropdown-menu-md dropdown-menu-right dropdownDropdownPreCampaigns">
                        <ul className="navi navi-hover mt-5 p-0">
                            {props.courseStatus === "Published" && (
                                <li className="navi-item mx-3">
                                    <span>
                                        <div tabIndex="0">
                                            <ButtonWithText
                                                variant="dangerInverted"
                                                leftIcon="edit-3"
                                                className="mb-5 w-100"
                                                onClick={() => {
                                                    changeStatus("Drafted");
                                                    setShowActionButtons(false);
                                                }}
                                            >
                                                Draft
                                            </ButtonWithText>
                                        </div>
                                    </span>
                                </li>
                            )}
                            {props.courseStatus === "Drafted" && (
                                <li className="navi-item mx-3">
                                    <span>
                                        <div tabIndex="0">
                                            <ButtonWithText
                                                variant="successInverted"
                                                leftIcon="send"
                                                className="mb-5 w-100"
                                                onClick={() => {
                                                    changeStatus("Published");
                                                    setShowActionButtons(false);
                                                }}
                                            >
                                                Publish
                                            </ButtonWithText>
                                        </div>
                                    </span>
                                </li>
                            )}
                            <li className="navi-item mx-3">
                                <Link
                                    // to={{ pathname: `https://www.leoron.com/${props.language.value}/${props.courseSlug}` }}
                                    to={{
                                        pathname: `${props.user.company.companyConfig.ecommerceSiteURL}/${
                                            props.language.value
                                        }${props.courseStatus === "Published" ? "/" : "/drafted-course/"}${
                                            props.courseSlug
                                        }`,
                                    }}
                                    target={"_blank"}
                                >
                                    <span>
                                        <div tabIndex="0">
                                            <ButtonWithText
                                                variant="neutralInverted"
                                                leftIcon="eye"
                                                className="mb-5 w-100"
                                                onClick={() => {
                                                    props.setStatusDeleteModal(true);
                                                    setShowActionButtons(false);
                                                }}
                                            >
                                                Preview on Site
                                            </ButtonWithText>
                                        </div>
                                    </span>
                                </Link>
                            </li>
                            {props.courseStatus === "Published" && (
                                <li className="navi-item mx-3">
                                    <Link
                                        // to={{ pathname: `https://www.leoron.com/${props.language.value}/${props.courseSlug}${props.language.value === "en" ? "#:~:text=ADD%20TO%20CART-,DOWNLOAD%20BROCHURE,-Course%20Overview" : "#:~:text=%D8%A3%D8%B6%D9%81%20%D8%A5%D9%84%D9%89%20%D8%A7%D9%84%D8%B9%D8%B1%D8%A8%D8%A9-,%D9%84%D8%AA%D8%AD%D9%85%D9%8A%D9%84%20%D8%A7%D9%84%D9%83%D8%AA%D9%8A%D8%A8,-%D9%86%D8%A8%D8%B0%D8%A9%20%D8%B9%D9%86%20%D8%A7%D9%84%D8%AF%D9%88%D8%B1%D8%A9"}` }}
                                        to={{
                                            pathname: `${props.user.company.companyConfig.ecommerceSiteURL}/api/ws/downloadBrochure/${props.courseSlug}/${props.selectedCampaign?.campaignTemplateCourseName}.pdf?ecommerceKey=${props.user.company.companyConfig.ecommerceKey}&lang=${props.language.value}`,
                                        }}
                                        target={"_blank"}
                                    >
                                        <span>
                                            <div tabIndex="0">
                                                <ButtonWithText
                                                    variant="primaryInverted"
                                                    leftIcon="download-cloud"
                                                    className="mb-5 w-100"
                                                    onClick={() => setShowActionButtons(false)}
                                                >
                                                    Download Brochure
                                                </ButtonWithText>
                                            </div>
                                        </span>
                                    </Link>
                                </li>
                            )}
                        </ul>
                    </Dropdown.Menu>
                </Dropdown>
            </div>
            {/* MOBILE ONLY BUTTONS */}
            <div className="courseQuickActionButtons-mobile">
                {props.courseStatus === "Published" && (
                    <Link
                        to={{
                            pathname: `${props.user.company.companyConfig.ecommerceSiteURL}/api/ws/downloadBrochure/${props.courseSlug}/${props.selectedCampaign?.campaignTemplateCourseName}.pdf?ecommerceKey=${props.user.company.companyConfig.ecommerceKey}`,
                        }}
                        target={"_blank"}
                    >
                        <ButtonWithText variant="primaryInverted" icon="download-cloud" />
                    </Link>
                )}
                <Link
                    to={{
                        pathname: `${props.user.company.companyConfig.ecommerceSiteURL}/${props.language.value}${
                            props.courseStatus === "Published" ? "/" : "/drafted-course/"
                        }${props.courseSlug}`,
                    }}
                    target={"_blank"}
                >
                    <ButtonWithText
                        variant="neutralInverted"
                        icon="eye"
                        onClick={() => props.setStatusDeleteModal(true)}
                    />
                </Link>
                {props.courseStatus === "Published" && (
                    <ButtonWithText variant="dangerInverted" icon="edit-3" onClick={() => changeStatus("Drafted")} />
                )}
                {props.courseStatus === "Drafted" && (
                    <ButtonWithText variant="successInverted" icon="send" onClick={() => changeStatus("Published")} />
                )}
            </div>
        </>
    );
}

const mapStateToProps = (state) => {
    return {
        quickActionsButton: state.campaigns.quickActionsButton,
        user: state.auth.user,
        isOpenCourseEditButton: state.campaigns.isOpenCourseEditButton,
        language: state.campaigns.language,
        courseSlug: state.campaigns.courseSlug,
        selectedCampaign: state.campaigns.selectedCampaign,
        courseStatus: state.campaigns.courseStatus,
        isCourseDetailLoading: state.campaigns.isCourseDetailLoading,
    };
};

const mapDispatchToProps = {
    ...campaignsRedux.actions,
};

export default connect(mapStateToProps, mapDispatchToProps)(CourseActionDropdown);
