import React from "react";
import { useDispatch } from "react-redux";
import { actions } from "app/popleads/modules/cms/redux/cmsRedux";
import ButtonWithText from "app/popleads/components/ui-kit/Button";
// import ChatGptDrawer from "app/popleads/components/Chat/ChatGptDrawer";

const ContactUsActions = () => {
    const dispatch = useDispatch();

    return (
        <div className="d-flex align-items-center" style={{ gap: "8px" }}>
            <div>
                <ButtonWithText
                    variant="successInverted"
                    leftIcon="plus"
                    onClick={() => dispatch(actions.toggleInquiryTypeAddButton(true))}
                >
                    Add Inquiry Type
                </ButtonWithText>
            </div>

            {/* <div
                style={{
                    width: "40px",
                    height: "40px",
                    overflow: "hidden",
                    borderRadius: "12px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    minWidth: "40px",
                    minHeight: "40px",
                }}
            >
                <ChatGptDrawer className={"m-0 btn-borderless"} />
            </div> */}
        </div>
    );
};

export default ContactUsActions;
