import { all } from "redux-saga/effects";
import { combineReducers } from "redux";

import * as auth from "../app/modules/Auth/_redux/authRedux";
import * as reportsRedux from "../app/popleads/modules/reports/redux/reportsRedux";
import * as appRedux from "../app/popleads/app/redux/appRedux";
import * as accountsRedux from "../app/popleads/modules/accounts/redux/accountsRedux";
import * as trainingRedux from "../app/popleads/modules/training/redux/trainingRedux";
import * as campaingsRedux from "../app/popleads/modules/campaigns/redux/campaignsRedux";
import * as surveyRedux from "../app/popleads/modules/survey/redux/surveyRedux";
import * as dealsRedux from "../app/popleads/modules/deals/redux/dealsRedux";
import * as activitiesRedux from "../app/popleads/modules/activities/redux/activitiesRedux";
import * as travelRedux from "../app/popleads/modules/travels/redux/travelRedux";
import * as settingsRedux from "../app/popleads/modules/settings/redux/settingsRedux";
import * as cmsRedux from "../app/popleads/modules/cms/redux/cmsRedux";
import { customersSlice } from "../app/modules/ECommerce/_redux/customers/customersSlice";
import { productsSlice } from "../app/modules/ECommerce/_redux/products/productsSlice";
import { remarksSlice } from "../app/modules/ECommerce/_redux/remarks/remarksSlice";
import { specificationsSlice } from "../app/modules/ECommerce/_redux/specifications/specificationsSlice";

export const rootReducer = combineReducers({
    auth: auth.reducer,
    customers: customersSlice.reducer,
    products: productsSlice.reducer,
    remarks: remarksSlice.reducer,
    specifications: specificationsSlice.reducer,
    reports: reportsRedux.reducer,
    app: appRedux.reducer,
    accounts: accountsRedux.reducer,
    training: trainingRedux.reducer,
    campaigns: campaingsRedux.reducer,
    survey: surveyRedux.reducer,
    deals: dealsRedux.reducer,
    activities: activitiesRedux.reducer,
    travel: travelRedux.reducer,
    settings: settingsRedux.reducer,
    cms: cmsRedux.reducer,
});

export function* rootSaga() {
    yield all([auth.saga()]);
}
