// import moment from "moment";

export const actionTypes = {
    SetSubheaderTitle: "[Action] Set Subheader Title",
    ShowContactUsActions: "[Action] Show Contact Us Action Buttons",
    ToggleInquiryTypeAddButton: "[Action] Toggle Inquiry Type Add Button",
    ToggleInquiryTypeEditButton: "[Action] Toggle Inquiry Type Edit Button",
    SetSelectedInquiryType: "[Action] Set Selected Inquiry Type",
    SetDeleteInquiryTypeModalId: "[Action] Set Delete Inquiry Type Modal Id",
    SetRefetchContactInquiries: "[Action] Set Refetch Contact Inquiries",
};

const initialCmsState = {
    subheaderTitle: undefined,
    path: undefined,
    showContactUsActions: false,
    isOpenInquiryTypeAddButton: false,
    isOpenInquiryTypeEditButton: false,
    selectedInquiryType: undefined,
    deleteInquiryTypeModalId: false,
    refetchContactInquiries: false,
};

export const reducer = (state = initialCmsState, action) => {
    switch (action.type) {
        case actionTypes.SetSubheaderTitle: {
            const { subheaderTitle, path } = action.payload;
            return { ...state, subheaderTitle, path };
        }

        case actionTypes.ShowContactUsActions: {
            const { showContactUsActions } = action.payload;
            return { ...state, showContactUsActions };
        }

        case actionTypes.ToggleInquiryTypeAddButton: {
            const { isOpenInquiryTypeAddButton } = action.payload;
            return { ...state, isOpenInquiryTypeAddButton };
        }

        case actionTypes.ToggleInquiryTypeEditButton: {
            const { isOpenInquiryTypeEditButton } = action.payload;
            return { ...state, isOpenInquiryTypeEditButton };
        }

        case actionTypes.SetSelectedInquiryType: {
            const { selectedInquiryType } = action.payload;
            return { ...state, selectedInquiryType };
        }

        case actionTypes.SetDeleteInquiryTypeModalId: {
            const { deleteInquiryTypeModalId } = action.payload;
            return { ...state, deleteInquiryTypeModalId };
        }

        case actionTypes.SetRefetchContactInquiries: {
            const { refetchContactInquiries } = action.payload;
            return { ...state, refetchContactInquiries };
        }

        default:
            return state;
    }
};

export const actions = {
    setSubheaderTitle: (subheaderTitle, path) => ({
        type: actionTypes.SetSubheaderTitle,
        payload: { subheaderTitle, path },
    }),

    setShowContactUsActions: (showContactUsActions) => ({
        type: actionTypes.ShowContactUsActions,
        payload: { showContactUsActions },
    }),

    toggleInquiryTypeAddButton: (isOpenInquiryTypeAddButton) => ({
        type: actionTypes.ToggleInquiryTypeAddButton,
        payload: { isOpenInquiryTypeAddButton },
    }),

    toggleInquiryTypeEditButton: (isOpenInquiryTypeEditButton) => ({
        type: actionTypes.ToggleInquiryTypeEditButton,
        payload: { isOpenInquiryTypeEditButton },
    }),

    setSelectedInquiryType: (selectedInquiryType) => ({
        type: actionTypes.SetSelectedInquiryType,
        payload: { selectedInquiryType },
    }),

    setDeleteInquiryTypeModalId: (deleteInquiryTypeModalId) => ({
        type: actionTypes.SetDeleteInquiryTypeModalId,
        payload: { deleteInquiryTypeModalId },
    }),

    setRefetchContactInquiries: (refetchContactInquiries) => ({
        type: actionTypes.SetRefetchContactInquiries,
        payload: { refetchContactInquiries },
    }),
};
