import React, { Suspense, lazy, useState } from "react";
import { Redirect, Switch, Route } from "react-router-dom";
import { LayoutSplashScreen } from "../_metronic/layout";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";
import * as auth from "./modules/Auth/_redux/authRedux";
import * as app from "./popleads/app/redux/appRedux";
import { get } from "./popleads/networking/RequestService";
import useDynamicSchemaDataManagement from "./popleads/hooks/useDynamicSchemaDataManagement";

const ReportsPage = lazy(() => import("./popleads/modules/reports/ReportsPage"));

const AccountsPage = lazy(() => import("./popleads/modules/accounts/AccountsPage"));

const TrainingPage = lazy(() => import("./popleads/modules/training/TrainingPage"));

const CampaignsPage = lazy(() => import("./popleads/modules/campaigns/CampaignsPage"));

const SurveyPage = lazy(() => import("./popleads/modules/survey/SurveyPage"));

const ActivitiesPage = lazy(() => import("./popleads/modules/activities/ActivitiesPage"));
const DealsPage = lazy(() => import("./popleads/modules/deals/DealsPage"));
const TravelPage = lazy(() => import("./popleads/modules/travels/TravelPage"));
const Settings = lazy(() => import("./popleads/modules/settings/SettingsPage"));
const CmsPage = lazy(() => import("./popleads/modules/cms/CmsPage"));

function BasePage(props) {
    const [isUserLoaded, setUserLoaded] = useState(0);
    const [isError, setIsError] = useState(false);
    const prevUrl = localStorage.getItem("prevUrl") ? localStorage.getItem("prevUrl") : "";

    useDynamicSchemaDataManagement();

    //const history = useHistory();
    // PopLeads change
    if (!isUserLoaded) {
        get("getLoggedinUser")
            .then(({ data: { user } }) => {
                if (user.company && user.company.companyConfig && user.company.companyConfig.currency) {
                    const currency = JSON.parse(user.company.companyConfig.currency);
                    props.setCurrency(currency);
                }
                props.setUser(user);
                setUserLoaded(true);
            })
            .catch((error) => {
                setIsError(true);
            });
    }
    //useEffect(() => {
    // console.log('Base page');
    // }, []) // [] - is required if you need only one call
    // https://reactjs.org/docs/hooks-reference.html#useeffect

    if (isUserLoaded) {
        if (prevUrl !== "") {
            localStorage.removeItem("prevUrl");
        }

        return (
            <Suspense fallback={<LayoutSplashScreen />}>
                <Switch>
                    {prevUrl !== "" && <Redirect exact from="/" to={prevUrl} />}
                    <Redirect exact from="/reports" to="/reports/sales" />
                    <Redirect exact from="/" to="/reports" />
                    <Route path="/reports" component={ReportsPage} />
                    <Route path="/accounts" component={AccountsPage} />
                    <Route path="/trainers" component={TrainingPage} />
                    <Route path="/campaigns" component={CampaignsPage} />
                    <Redirect exact from="/survey" component={SurveyPage} to="/survey/dashboard" />
                    <Route path="/survey" component={SurveyPage} />
                    <Route path="/deals" component={DealsPage} />
                    <Route path="/travel" component={TravelPage} />
                    <Route path="/activities" component={ActivitiesPage} />
                    <Route path="/settings" component={Settings} />
                    <Route path="/cms" component={CmsPage} />

                    {/*<ContentRoute path="/dashboard" component={DashboardPage} />
          <ContentRoute path="/builder" component={BuilderPage} />
          <ContentRoute path="/my-page" component={MyPage} />
          <Route path="/google-material" component={GoogleMaterialPage} />
          <Route path="/react-bootstrap" component={ReactBootstrapPage} />
          <Route path="/e-commerce" component={ECommercePage} />
          <Route path="/user-profile" component={UserProfilepage} />*/}
                    <Redirect to="/error/404" />
                </Switch>
            </Suspense>
        );
    } else if (isError) {
        return <Redirect to="/error/500" />;
    }
    return <LayoutSplashScreen />;
}

//PopLeads change
const mapDispatchToProps = {
    ...auth.actions,
    ...app.actions,
};

export default injectIntl(connect(null, mapDispatchToProps)(BasePage));
